













































































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";
import BuiltinService from "../../../services/buitin-service";

@Component({
  components: {
    Header,
    Sidebar,
    Loader,
  },
})
export default class Employee extends Vue {
  loading = true;
  public filter = null;
  public filterOn = [];
  public import_response = '';
  public fields = [
    { key: "code", sortable: true },
    { key: "title", sortable: false },
    { key: "nationality", sortable: false },
    { key: "actual_designation", sortable: false, label: 'Designation'},
    { key: "joined_date", sortable: false, label : 'DOJ' },
    { key: "contact_num", sortable: false, label: 'Contact'},
  ];

  public columns = [
          {
            label: 'code',
            field: 'code',
          },
          {
             label: 'title',
            field: 'title',
          },
          {
             label: 'nationality',
            field: 'nationality',
          },
          {
             label: 'designation',
            field: 'actual_designation',
          },
          {
             label: 'doj',
            field: 'joined_date',
          },
          {
             label: 'contact',
            field: 'contact_num',
          },
  ];
          

  public selected_expat = "all";
  public option_expat = [
    { text: "All Employees", value: "all" },
    { text: "Saudi", value: "saudi" },
    { text: "Non Saudi", value: "Non Saudi" },
  ];

  public option_dept: any = [];
  public selected_dept = 0;
  public option_emptype: any = [];
  public selected_emptype = 0;
  public option_branch: any = [];
  public selected_branch = 0;
  public option_bloodgroup: any = [];
  public selected_bloodgroup = 0;
  public selected_effective_date_from = "";
  public selected_effective_date_to = "";

  public selected_items = [];
  public items = [];
  public currentPage = 1;
  get rows() {
    return this.selected_items.length;
  }

  public import_file: any = null;

  retrieve() {
    this.loading = true;
    this.import_response = '';
    HRMService.getAll()
      .then((response) => {
        this.items = response.data;
        this.selected_items = this.items;
        this.loading = false;
        // console.log(this.selected_items);
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  submit_import() {
    this.import_response = 'Please wait, it will take time.'
    this.loading = true;
    this.import_response = '';
    HRMService.post_employee_file(this.import_file)
      .then((response) => {
        this.import_response = 'Uploaded.'
        this.loading = false;
      })
      .catch((e) => {
        this.import_response = 'Error occured while uploading.'
        this.loading = false;
        console.log(e);
      });
  }

  refreshList() {
    this.get_filters();
    this.retrieve();
  }

  expatchange() {
    if (this.selected_expat == "all") {
      this.selected_items = this.items;
    } else {
      this.selected_items = this.items.filter(
        (x) => x["expats"] == this.selected_expat
      );
    }
  }

  bloodgroupchange() {
    if (this.selected_bloodgroup == 0) {
      this.selected_items = this.items;
    } else {
      let selectedoption = this.option_bloodgroup.filter(
        (y: any) => y["value"] == this.selected_bloodgroup
      );
      this.selected_items = this.items.filter(
        (x) => x["blood_group"] == selectedoption[0]["text"]
      );
    }
  }

  effectivedateto() {
    // console.log(this.selected_effective_date_to)
    if (this.selected_effective_date_to) {
      this.selected_items = this.items.filter(
        (x) => x["employment_effective_date"] < this.selected_effective_date_to
      );
    }
  }
  effectivedatefrom() {
    if (this.selected_effective_date_from) {
      this.selected_items = this.items.filter(
        (x) =>
          x["employment_effective_date"] < this.selected_effective_date_from
      );
    }
  }

  onFiltered(filteredItems: any) {}

  mounted() {
    this.retrieve();
    this.get_filters();
    // console.log(localStorage.getItem('jwt'))
  }

  get_filters() {
    this.loading = true;
    BuiltinService.getallbuiltin()
      .then((response) => {
        this.option_dept = [];
        let dept: any = response.data["department"];
        this.option_dept = dept.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_dept.push({ value: 0, text: "Please select an option" });

        this.option_bloodgroup = [];
        let bloodgroup = response.data["blood_group"];
        this.option_bloodgroup = bloodgroup.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_bloodgroup.push({
          value: 0,
          text: "Please select an option",
        });

        this.option_emptype = [];
        let employment_type = response.data["employment_type"];
        this.option_emptype = employment_type.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_emptype.push({ value: 0, text: "Please select an option" });

        this.option_branch = [];
        let branch = response.data["branch"];
        this.option_branch = branch.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_branch.push({ value: 0, text: "Please select an option" });
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onRowClicked(item: any, index: any, event: any) {
    this.$router.push({ name: "Create", params: { id: item.id.toString() } });
  }
}
